import * as React from "react"
import '../style.css'
import App from "./App";
import SSRProvider from 'react-bootstrap/SSRProvider';


const IndexPage = () => {
  return (
    <SSRProvider>
      <App />
    </SSRProvider>
  )
}

export default IndexPage
